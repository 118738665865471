import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface OpenNonCssDilogCommandPort {
  openNonCssDialog(issimulatiom: boolean, clientId: string): Observable<boolean>;
}

export const OPEN_NON_CSS_DIALOG_COMMAND_PORT = new InjectionToken<OpenNonCssDilogCommandPort>(
  'OPEN_NON_CSS_DIALOG_COMMAND_PORT',
);
