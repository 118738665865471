import { NgModule } from '@angular/core';
import { CompanyDetailsState } from './company-details.state';
import { GETS_NUMBER_OF_EMPLOYEES_QUERY } from '../ports/primary/gets-number-of-employees.query-port';
import { GETS_NUMBER_OF_CLIENTS_QUERY } from '../ports/primary/gets-number-of-clients.query-port';
import { GETS_COMPANY_TYPES_QUERY } from '../ports/primary/gets-company-types.query-port';
import { GETS_COMPANY_DETAILS_QUERY_PORT } from '../ports/primary/gets-company-details.query-port';
import { SAVES_COMPANY_DETAILS_QUERY_PORT } from '../ports/primary/saves-company-details.query-port';

@NgModule({
  providers: [
    CompanyDetailsState,
    { provide: GETS_COMPANY_TYPES_QUERY, useExisting: CompanyDetailsState },
    { provide: GETS_NUMBER_OF_CLIENTS_QUERY, useExisting: CompanyDetailsState },
    { provide: GETS_NUMBER_OF_EMPLOYEES_QUERY, useExisting: CompanyDetailsState },
    { provide: GETS_COMPANY_DETAILS_QUERY_PORT, useExisting: CompanyDetailsState },
    { provide: SAVES_COMPANY_DETAILS_QUERY_PORT, useExisting: CompanyDetailsState },
  ],
})
export class CompanyDetailsStateModule {}
