<div class="cs-card" [formGroup]="consentsForm" data-selector="marketing-preferences-list">
  <ng-container *ngFor="let emailConsent of consents$ | async">
    <div class="d-flex align-items-center">
      <label class="cs-toggle-switchbox cs-mr-10">
        <input type="checkbox" [formControlName]="emailConsent.name" />
        <span class="cs-toggle-slider"></span>
      </label>
      <p>{{ 'user_marketing_preferences_email_consents_' + emailConsent.name | language }}</p>
    </div>
  </ng-container>
</div>
