/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { Inject, Injectable } from '@angular/core';
import { UserProfileUpdatedEvent } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { GetsUserFromToken, GETS_USER_FROM_TOKEN } from '@app.cobiro.com/user/core';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { BehaviorSubject, map, Observable, shareReplay, tap } from 'rxjs';
import { CountryQuery } from '../ports/primary/country.query';
import { GetsCountriesQueryPort } from '../ports/primary/gets-countries.query-port';
import { GetsUserDetailsQueryPort } from '../ports/primary/gets-user-details.query-port';
import { UpdatesUserDetailsQueryPort } from '../ports/primary/updates-user-details.query-port';
import { UserDetailQuery, UserDetailQueryPartial } from '../ports/primary/user-details.query';
import { CountryDto } from '../ports/secondary/country.dto';
import {
  GetsCountriesDtoPort,
  GETS_COUNTRIES_DTO,
} from '../ports/secondary/gets-countries.dto-port';
import {
  GetsUserDetailsDtoPort,
  GETS_USER_DETAILS_DTO,
} from '../ports/secondary/gets-user-details.dto-port';
import {
  UpdatesUserDetailsDtoPort,
  UPDATES_USER_DETAILS_DTO,
} from '../ports/secondary/updates-user-details.dto-port';
import { UserDetailDto } from '../ports/secondary/user-details.dto';
import { SavesEmailConsentQueryPort } from '../ports/primary/saves-email-consent.query-port';
import { GetsEmailConsentsQueryPort } from '../ports/primary/gets-email-consents.query-port';
import { EmailConsentQuery } from '../ports/primary/email-consent.query';
import {
  GETS_EMAIL_CONSENTS_DTO_PORT,
  GetsEmailConsentsDtoPort,
} from '../ports/secondary/gets-email-consents.dto-port';
import {
  SAVES_EMAIL_CONSENT_DTO_PORT,
  SavesEmailConsentDtoPort,
} from '../ports/secondary/saves-email-consent.dto-port';
import { IsSaveInProgressCommandPort } from '../ports/primary/is-save-in-progress.command-port';

@Injectable()
export class UserDetailsState
  implements
    GetsUserDetailsQueryPort,
    UpdatesUserDetailsQueryPort,
    GetsCountriesQueryPort,
    GetsEmailConsentsQueryPort,
    SavesEmailConsentQueryPort,
    IsSaveInProgressCommandPort
{
  public saveInProgress$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(GETS_USER_DETAILS_DTO)
    private readonly _getsUserDetails: GetsUserDetailsDtoPort,
    @Inject(UPDATES_USER_DETAILS_DTO)
    private readonly _updateUserDetails: UpdatesUserDetailsDtoPort,
    @Inject(SAVES_EMAIL_CONSENT_DTO_PORT)
    private readonly _savesEmailConsentDtoPort: SavesEmailConsentDtoPort,
    @Inject(GETS_EMAIL_CONSENTS_DTO_PORT)
    private readonly _getsEmailConsentsDtoPort: GetsEmailConsentsDtoPort,
    private readonly _alert: HuiAlert,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    @Inject(GETS_USER_FROM_TOKEN) private readonly _getsUser: GetsUserFromToken,
    @Inject(GETS_COUNTRIES_DTO)
    private readonly _getCountriesDto: GetsCountriesDtoPort,
  ) {
    this._getCountriesDto.getCountries().subscribe(countries => {
      this._countries$.next(countries);
    });
  }
  isInProgress(): Observable<boolean> {
    return this.saveInProgress$.asObservable();
  }
  getEmailConsent(): Observable<EmailConsentQuery[]> {
    return this._getsEmailConsentsDtoPort.getEmailConsent();
  }
  saveEmailConsent(channel: string, consent: boolean): Observable<boolean> {
    this.saveInProgress$.next(true);
    return this._savesEmailConsentDtoPort.saveEmailConsent(channel, consent).pipe(
      tap({
        next: () => {
          this.saveInProgress$.next(false);
          this._alert.open('success', 'user_marketing_preferences_change_success');
        },
        error: () => {
          this.saveInProgress$.next(false);
          this._alert.open('error', 'user_marketing_preferences_change_fail');
        },
      }),
    );
  }

  private readonly _countries$ = new BehaviorSubject<CountryDto[] | null>(null);

  getCountriesQuery(): Observable<CountryQuery[]> {
    return this._countries$.asObservable().pipe(
      map(countries => countries && countries.map(({ name, code }) => ({ name, code }))),
      shareReplay(1),
    );
  }

  get(): Observable<UserDetailQuery> {
    const user = this._getsUser.get();

    if (!user) {
      throw new Error('No user id');
    }
    return this._getsUserDetails.get(user.userId);
  }
  update(dto: UserDetailQueryPartial): Observable<UserDetailQuery> {
    const userId = this._getsUser.get()?.userId;

    if (!userId) {
      throw new Error('User id is missing');
    }
    return this._updateUserDetails
      .update(UserDetailDto.fromQueryPartialToDtoPartial(dto), userId)
      .pipe(
        tap({
          next: details => {
            this._alert.open('success', '_save_changes_success');
            this._applicationBus.dispatch(
              new UserProfileUpdatedEvent({
                firstName: dto.firstName,
                lastName: dto.lastName,
                avatar: details.avatar,
                phoneNumber: details.phoneNumber,
                country: details.country,
              }),
            );
          },
          error: () => {
            this._alert.open('error', '_save_changes_error');
          },
        }),
      );
  }
}
