import { NgModule } from '@angular/core';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { HasTeamBillingGuard } from '../adapters/primary/guards/has-team-billing.guard';
import { GetTeamSettingsFormErrorServiceModule } from '../adapters/secondary/infrastructure/get-team-settings-form-error/get-team-settings-form-error.service-module';
import { HttpChargebeeConfirmPaymentServiceModule } from '../adapters/secondary/infrastructure/http-chargebee-confirm-payment/http-chargebee-confirm-payment.service-module';
import { HttpGetsCountriesServiceModule } from '../adapters/secondary/infrastructure/http-gets-countries/http-gets-countries.service-module';
import { HttpPaymentsServiceModule } from '../adapters/secondary/infrastructure/http-payments/http-payments.service-module';
import { HttpTeamCreatePaymentIntentServiceModule } from '../adapters/secondary/infrastructure/http-team-create-payment-intent/http-team-create-payment-intent.service-module';
import { HttpTeamCreatePaymentSourceServiceModule } from '../adapters/secondary/infrastructure/http-team-create-payment-source/http-team-create-payment-source.service-module';
import { HttpTeamSettingsServiceModule } from '../adapters/secondary/infrastructure/http-team-settings/http-team-settings.service-module';
import { PaymentMethodsStateModule } from '../application/state/payment-methods.state-module';
import { TeamSettingsStateModule } from '../application/state/team-settings.state-module';
import { CompanyDetailsStateModule } from '../application/state/company-details.state-module';
import { HttpCompanyDetailsServiceModule } from '../adapters/secondary/infrastructure/http-company-details/http-company-details.service-module';

@NgModule({
  imports: [
    PaymentMethodsStateModule,
    TeamSettingsStateModule,
    CompanyDetailsStateModule,
    HttpCompanyDetailsServiceModule,
    HttpTeamSettingsServiceModule,
    HttpPaymentsServiceModule,
    GetTeamSettingsFormErrorServiceModule,
    HttpChargebeeConfirmPaymentServiceModule,
    HttpGetsCountriesServiceModule,
    HttpTeamCreatePaymentIntentServiceModule,
    HttpTeamCreatePaymentSourceServiceModule,
  ],
  providers: [HasTeamBillingGuard],
})
export class CobiroProRewriteSettingsProvidersModule {}
